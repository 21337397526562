"use client";
import React from "react";
import Footer from "@/components/footer";
import { text } from "@/ultils/constant";
import dynamic from "next/dynamic";

const Container = dynamic(() => import("@/components/container"), {
  ssr: false,
});

const Search = dynamic(() => import("@/components/search"), {
  ssr: false,
});

export default function Home() {
  return (
    <main className="w-full pt-[13%] md:pt-[7%] 2xl:pt-[5%]">
      <div className="text-center pb-2">
        <h1 className="text-xl md:leading-relaxed font-bold 2xl:text-2xl">
          {text.HOME_TITLE}
        </h1>
        <p className="text-sm md:text-base font-light 2xl:text-md">
          {text.HOME_DESCRIPTION}
        </p>
      </div>
      <Search />
      <div className="flex gap-2">
        <Container />
      </div>
      <Footer />
    </main>
  );
}
